/**
 * Blogs Java Script Library methods
 */

/**
 * Set onload function for blog pages
 */
$(document).ready(function () {
    initBlogs();
});

/**
 * define global constants
 */
var RSS_CONTAINER_SELECTOR = '.dmRssContainer';
var RSS_CONTAINER_MORE_POSTS_BUTTON = '#dmMorePostsButton';
var RSS_CONTAINER_MORE_POSTS_INNER_DIV = '.dmMorePostsButtonClass';
var POST_ITEM = '.dmRssItem';
var POST_ITEM_LINK = '.dmRssA';

/**
 * @description: Initialize special blogs client variables
 */
function initBlogs() {

    // set the home link href (only in case this is an inner post page)
    if ($('#dmPostBackToMain').length > 0) {
        $('#dmPostBackToMain').css('display', 'none');
        $(Parameters.HomeLinkSelector).attr(
            'href',
            $('#dmPostBackToMain').attr('href')
        );
    }
}

// This is used by the server template.
// eslint-disable-next-line no-unused-vars
function fetchMoreBlogItems(rssURL) {

        var dataString = new Object();

        dataString.commandID = 'loadMorePosts';
        dataString._url = rssURL;
        dataString._elementId = $(RSS_CONTAINER_SELECTOR).attr('id');
        dataString._pageUUID = Parameters.InitialPageUuid;
        dataString._morePostsLabel = $(
            RSS_CONTAINER_MORE_POSTS_INNER_DIV
        ).html(); // more posts label
        dataString._editor = $.DM.insideEditor();

        var ajaxUrl = '/_dm/s/rt/api/public/wpl/site/' + Parameters.SiteAlias;

    $.ajax({
        url: ajaxUrl,
        type: 'post',
        data: JSON.stringify(dataString),
        async: true,
        contentType: 'application/json',
        success: function (data) {
            var moreButton = $(RSS_CONTAINER_MORE_POSTS_BUTTON);
            if (data.postList) {
                var newMarkup = $('<div></div>')
                    .append($(data.postList).find(RSS_CONTAINER_SELECTOR))
                    .html();
                var lastRssItem =
                    $(POST_ITEM_LINK)[$(POST_ITEM).length - 1];
                var lastRssHref = $(lastRssItem).attr('href');

                // handle rewritten links
                var lastPost = $.DM.getQueryParam(lastRssHref, 'post_id');
                var $newMarkup = $(newMarkup);
                var newRssFeeds = $(POST_ITEM_LINK, $newMarkup);
                var deleteIndex = -1;

                for (var i = 0; i < newRssFeeds.length; i++) {
                    var currPostUrl = $(newRssFeeds[i]).attr('href');

                    if (
                        lastPost ==
                        $.DM.getQueryParam(currPostUrl, 'post_id')
                    ) {
                        deleteIndex = i;
                        break;
                    }
                }

                if (deleteIndex > -1) {
                    for (var i = 0; i <= deleteIndex; i++) {
                        $newMarkup
                            .find($(newRssFeeds[i]))
                            .parent()
                            .remove();
                    }
                }

                // insert the new items before the 'fetch more posts' button
                var itemsToAdd = $newMarkup.html();
                $(itemsToAdd).insertBefore(moreButton);

                // remove the old 'fetch more posts' button
                moreButton.remove();

                initBlogs();
                if (jQuery.DM.isUseLayout()) {
                    jQuery.layoutManager.initLayout();
                }
            }
        },
    });
}